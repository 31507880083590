import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import some from 'lodash/some';
import CommonStore from '@audacious/web-common/fluxible/CommonStore';
import QueryDocumentStatus from '../common/query/query-document-status';
import formatDate from '../common/util/format-date';
import {
    formatPhoneNumber,
    formatGender,
    formatPatientName,
    formatAddress,
} from '../common/util/format-patient-fields';

const initialState = {
    patients: [],
};

function formatResults(results, oid) {
    return results.map(res => {
        const { patient, oid: subOid, entityName } = res;
        if (!patient) {
            return res;
        }

        // Each result may have a sub-OID that correlates to a sub organization.
        // We need use the parent OID for the QD call and for correlating to a
        // source to show in the patient results table.
        // For search history, parent OIDs are in result object while for the initial
        // query they are at a higher level.
        res.oid = oid || subOid;
        res.entityName = entityName;

        const {
            dob,
            firstName,
            middleName,
            lastName,
            address1,
            address2,
            city,
            state,
            zip,
            homePhone,
            cellPhone,
            workPhone,
        } = patient;

        res.patient.gender = formatGender(patient.gender || '');

        res.patient.dobTimestamp = dob;
        res.patient.dob = formatDate(dob);

        res.patient.fullName = formatPatientName({
            firstName,
            middleName,
            lastName,
        });

        res.patient.fullAddress = formatAddress({
            address1,
            address2,
            city,
            state,
            zip,
        });

        res.patient.homePhone = formatPhoneNumber(homePhone);
        res.patient.cellPhone = formatPhoneNumber(cellPhone);
        res.patient.workPhone = formatPhoneNumber(workPhone);

        return res;
    });
}

class PatientResultsStore extends CommonStore {
    constructor(dispatcher) {
        super(dispatcher, initialState);
    }

    // Use callback in setState when referencing the previous state
    addResults({ results, oid }) {
        if (results) {
            const formattedResult = formatResults(results, oid);

            const newState = this.getState();
            if (!newState.patients) {
                newState.patients = [];
            }

            formattedResult.forEach(result => {
                if (!result.patientId || result.patientId.length === 0) {
                    // dont add if no patient id exists.
                    // This should ever happen but you never know
                    return;
                }

                const resultExists = some(newState.patients, patient => {
                    const existingId = get(patient.patientId[0], 'patientId');
                    const existingAuthority = get(
                        patient.patientId[0],
                        'assigningAuthority',
                    );

                    const newId = get(result.patientId[0], 'patientId');
                    const newAuthority = get(
                        result.patientId[0],
                        'assigningAuthority',
                    );

                    return (
                        existingId === newId &&
                        existingAuthority === newAuthority
                    );
                });

                if (!resultExists) {
                    newState.patients.push(result);
                }
            });

            this.setState(newState);
        }

        return this.getState();
    }

    clearResults() {
        const newState = this.getState();
        newState.patients = cloneDeep(initialState.patients);
        this.setState(newState);
    }

    changePatientsSelected(args) {
        const { patient, select } = args;
        const { patients } = this.getState();
        const patientId = get(patient, 'patientId[0].patientId');

        const newPatients = patients.map(p => {
            const pId = get(p, 'patientId[0].patientId');
            if (!patient || pId === patientId) {
                return {
                    ...p,
                    selected: select,
                };
            }

            return p;
        });

        this.setState({ patients: newPatients });
    }

    setQueryDocumentStatus(args) {
        const {
            patientIds,
            oid,
            results,
            isError,
            isTimedOut = false,
            retrievedResult = false,
        } = args;

        const { patients } = this.getState();

        let ids = patientIds || [];
        let status;
        let matchOid = false;

        if (!isTimedOut && retrievedResult) {
            const resultPatientId = get(results, 'patientId[0].patientId');

            // If the patientId is returned, update only patient result QD statuses associated
            // with it. Otherwise if the oid is returned update patients with that oid. If
            // neither patientId nor oid are returned update all patient statuses in the QD
            // query since we don't know which errored.
            if (!resultPatientId) {
                if (oid) {
                    matchOid = true;
                } else {
                    status = QueryDocumentStatus.ERROR;
                }
            } else {
                ids = [resultPatientId];
                const documents = get(results, 'documents');

                status =
                    !documents || documents.length === 0
                        ? QueryDocumentStatus.NO_RESULTS
                        : QueryDocumentStatus.SUCCESS;
            }
        } else {
            status = QueryDocumentStatus.LOADING;
        }

        status = isTimedOut ? QueryDocumentStatus.TIMED_OUT : status;
        status = isError ? QueryDocumentStatus.ERROR : status;

        // Update patient result QD statuses
        const newPatients = patients.map(p => {
            let patientMatch = false;

            if (matchOid) {
                patientMatch = p.oid === oid;
            } else {
                const pId = get(p, 'patientId[0].patientId');
                patientMatch = ids.includes(pId);
            }

            if (patientMatch) {
                // Prevent completed QD statuses from being changed to error
                // when another QD result's patientId wasn't returned.
                if (
                    (p.status === QueryDocumentStatus.SUCCESS ||
                        p.status === QueryDocumentStatus.NO_RESULTS) &&
                    status !== QueryDocumentStatus.LOADING
                ) {
                    return p;
                }

                return {
                    ...p,
                    status: status || p.status,
                };
            }

            return p;
        });

        this.setState({ patients: newPatients });
    }
}

PatientResultsStore.storeName = 'PatientResultsStore';
PatientResultsStore.handlers = {
    ADD_PATIENT_RESULT: 'addResults',
    CLEAR_PATIENT_RESULTS: 'clearResults',
    CHANGE_PATIENTS_SELECTED: 'changePatientsSelected',
    SET_QUERY_DOCUMENT_STATUS: 'setQueryDocumentStatus',
    LOGOUT: 'resetState',
};

export default PatientResultsStore;
