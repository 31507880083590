import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';
import applyFluxibleContext from '@audacious/web-common/fluxible/applyFluxibleContext';
import { AuthenticatedPage } from '@audacious/client';
import FacilitySelection, {
    FacilityHeaderFeature,
} from '@audacious/pulse-facility-selection';
// eslint-disable-next-line import/no-unresolved, import/no-webpack-loader-syntax
import PdfjsWorker from 'worker-loader!pdfjs-dist/build/pdf.worker.mjs';
import PDFViewer from '@audacious/components/components/PDFViewer';
import { SEARCH } from './pages/routes';
import SearchPage from './pages/search-page/search-page';

import './application.scss';

PDFViewer.setWorker(new PdfjsWorker());

class Application extends PureComponent {
    render() {
        return (
            <AuthenticatedPage
                id="pulse-app-page"
                variant="grey"
                additionalFeatures={[
                    <FacilityHeaderFeature key="facilityHeader" />,
                ]}
            >
                <FacilitySelection>
                    <Switch>
                        <Route path={SEARCH}>
                            <SearchPage />
                        </Route>
                        <Route path="*">
                            <Redirect to={SEARCH} />
                        </Route>
                    </Switch>
                </FacilitySelection>
            </AuthenticatedPage>
        );
    }
}

Application.defaultProps = {};

Application.propTypes = {
    fluxibleContext: PropTypes.shape({
        service: PropTypes.shape({
            loadDirectory: PropTypes.func.isRequired,
        }).isRequired,
    }).isRequired,
};

export default applyFluxibleContext(Application);
